import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren } from "react";
import { getStage } from "src/context";

// Helper function to wrap the Posthog provider so that we don't opt-in to posthog tracking in non-production environments
export function PostHogProviderWrapper({ children }: PropsWithChildren) {
  const stage = getStage();
  if (stage !== "prod") return <>{children}</>;

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
