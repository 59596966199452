import { DateRangeFilterValue, Value } from "@homebound/beam";
import { Bill, BillStatus, BillType, LienWaiverCondition } from "src/generated/graphql-types";
import { DeepPartial } from "src/utils";

export type InvoicesFilter = {
  address: string[];
  status: string[];
  dueDateRange: DateRangeFilterValue<Value> | undefined;
  paidDateRange: DateRangeFilterValue<Value> | undefined;
  marketId: string[] | undefined;
};

export function getInvoicePrefix(bill: DeepPartial<Bill>, hidePrefix: boolean = false) {
  if (bill.type?.code === BillType.Deposit) return "Deposit";
  if (hidePrefix) return "";
  return "Invoice";
}

export const isApprovedStatus = (status: BillStatus): boolean => {
  return [
    BillStatus.ReadyToPay,
    BillStatus.PartiallyPaid,
    BillStatus.PendingConditionalLienWaiver,
    BillStatus.PendingUnconditionalLienWaiver,
  ].includes(status);
};

export const lienWaiverConditionMapper = (condition: LienWaiverCondition): string => {
  const mapper: Record<LienWaiverCondition, string> = {
    [LienWaiverCondition.Conditional]: "Cond",
    [LienWaiverCondition.Unconditional]: "Uncond",
  };
  return mapper[condition];
};
