import { datadogLogs, LogsInitConfiguration } from "@datadog/browser-logs";
import { datadogRum, DefaultPrivacyLevel, RumInitConfiguration } from "@datadog/browser-rum";
import * as FullStory from "@fullstory/browser";
import posthog from "posthog-js";
import { getGraphQLBaseUrl, getStage } from "src/context";
import { CurrentUserQuery } from "src/generated/graphql-types";

/* Since Stage is meaningful in the GraphQL context as well, grab off the ReturnType vs. exporting from context.ts */
type Stage = ReturnType<typeof getStage>;
type FsWindow = { FS?: { getCurrentSession?: () => string | null } };

/** Enable third-party analytics tools */
export async function enableAnalytics(stage = getStage(), _fsWindow: FsWindow = window as FsWindow) {
  const waitForFullstoryPromise = enableFullStory(stage, _fsWindow);
  enablePosthog(stage);
  const datadogEnabled = enableDatadog(stage);
  await waitForFullstoryPromise;

  if (datadogEnabled) {
    datadogRum.startSessionReplayRecording();
  }
}

/**
 * This function enables FullStory via their @fullstory/browser package. The `init` call pulls the script at runtime,
 * so it's not deterministic as to when FullStory is actually up and running. We have a helper method that polls
 * the FS global to determine if it's fully initialized.
 */
async function enableFullStory(stage: Stage, _fsWindow: FsWindow): Promise<boolean> {
  if (stage !== "prod") {
    return false;
  }
  FullStory.init({ orgId: "P2TFT" });
  return waitForFullStoryToInit({ interval: 200, maxTimeToWait: 10_000 }, _fsWindow);
}

export function setAnalyticsUser(user?: CurrentUserQuery["currentUser"]): void {
  const { email, name, userType, isBeingImpersonated, tradePartnerUsers } = user || {};
  // Attach user identity to FullStory; should be run 1x on every full page load, per:
  // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
  const { FS } = window as any;
  // does not log session for homebound users
  const isHomebounder = email?.endsWith("@homebound.com") || isBeingImpersonated === true;

  // Set the first trade partner as the current user
  const currentUserId = tradePartnerUsers?.first?.tradePartner.id;
  if (FS && currentUserId && email && userType && !isHomebounder) {
    FS.identify(`${userType}::${currentUserId}`, { email });
  }

  // Attach trade partner as the current user to PostHog
  posthog.identify(`${userType}::${currentUserId}`, { email });

  // Identify user with Datadog Real User Monitoring
  // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-sessions
  if (currentUserId) {
    datadogRum.setUser({ currentUserId, name, email });
    datadogLogs.setUser({ currentUserId, name, email });
  } else {
    datadogRum.removeUser();
    // There isn't a datadogLogs.removeUser, so remove each key
    ["currentUserId", "name", "email"].forEach((key) => datadogLogs.removeUserProperty(key));
  }
}

/**
 * This method waits for FullStory to fully initialize. The main reason we're doing this is because of the Datadog
 * issue mentioned in `enableAnalytics`. If that upstream issue is resolved, this all gets much much simpler
 */
async function waitForFullStoryToInit(
  { interval, maxTimeToWait }: { interval: number; maxTimeToWait: number },
  _fsWindow: FsWindow,
): Promise<boolean> {
  return new Promise(async (resolve) => {
    const maxIntervals = maxTimeToWait / interval;

    for (let i = 0; i < maxIntervals; i++) {
      // We're using the global FS here instead of the @fullstory/browser `getCurrentSessionURL` method because it prints
      // a console.warn statement each time we poll and the package isn't ready. This creates a lot of noise in the console
      // so we don't use it.
      if (_fsWindow.FS && _fsWindow.FS.getCurrentSession && _fsWindow.FS.getCurrentSession() !== null) {
        resolve(true);
        return;
      } else {
        await wait(interval);
      }
    }

    console.info(
      `FullStory did not initialize in ${maxTimeToWait / 1000} seconds. An ad-blocker likely blocked the request.`,
    );
    resolve(false);
  });
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const DATADOG_IGNORED_ERROR_MESSAGES = /ResizeObserver loop limit exceeded/;
function enableDatadog(stage: Stage): boolean {
  if (stage === "local") {
    return false;
  }

  const commonOps: Omit<RumInitConfiguration, "applicationId"> & LogsInitConfiguration = {
    clientToken: "pubdcf838cb7718694effa7a06ae59e6ad4",
    site: "datadoghq.com",
    service: "trades-frontend",
    env: stage,
    version: VITE_GIT_COMMIT ?? "local",
  };

  // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    ...commonOps,
    applicationId: "2da9adb3-21b8-47d9-97f8-31b00db13f80",
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    allowedTracingOrigins: [getGraphQLBaseUrl()],
    beforeSend: (event) => {
      // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-and-control-rum-data
      if (event.type === "error" && DATADOG_IGNORED_ERROR_MESSAGES.test(event.error.message)) {
        return false;
      }
    },
    defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
    enableExperimentalFeatures: [
      "clickmap", // enable heatmap (https://docs.datadoghq.com/real_user_monitoring/heatmaps/#prerequisites)
    ],
  });

  datadogLogs.init({
    ...commonOps,
    forwardConsoleLogs: [
      // Remember that we pay per-log ingested. You should likely avoid reporting debug-level log messages
      // unless you're actively debugging a major, global issue.
      // "debug",
      "info",
      "warn",
      "error",
    ],
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: (event) => {
      return !DATADOG_IGNORED_ERROR_MESSAGES.test(event.message);
    },
  });

  return true;
}

function enablePosthog(stage: Stage) {
  if (stage !== "prod") {
    return false;
  }

  posthog.init("phc_813WZxVPPPSHtUKAmrG7PNdXHXWvkPyexWzcsJV5KEk", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
  });
}
