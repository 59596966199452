import {
  Button,
  ButtonModal,
  Chip,
  Css,
  DateRange,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  ScrollableContent,
  column,
  emptyCell,
  simpleHeader,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { QueryResultHandler, SearchBox, chipCell, dateCell, priceCell } from "src/components";
import { useQueryStorage } from "src/components/useQueryStorage";
import {
  CurrentUserQuery,
  InputMaybe,
  InvoicesPageQuery,
  InvoicesPage_BillFragment,
  useCurrentUserQuery,
  useInvoicesPageQuery,
} from "src/generated/graphql-types";
import { billStatusChipTypeMapper, foldEnum, isDefined } from "src/utils";
import { TableActions } from "../layout/TableActions";
import { dateFilter } from "../purchaseOrders/PurchaseOrdersPage";
import { getInvoiceUrl } from "../routesDef";
import { InvoicesSummary } from "./InvoicesSummary";
import { InvoiceFilterModal } from "./components/InvoiceFilterModal";
import { InvoicesFilter, getInvoicePrefix } from "./utils";

export function InvoicesPage() {
  const userQuery = useCurrentUserQuery();

  return QueryResultHandler<CurrentUserQuery>({
    result: userQuery,
    render: function Page(useQuery) {
      const userMarkets =
        useQuery.currentUser?.tradePartnerUsers
          ?.flatMap((tpu) => tpu.tradePartnerContact?.markets?.map((market) => market.id))
          .compact() ?? [];
      const query = useInvoicesPageQuery({ variables: { filter: {} } });

      return QueryResultHandler<InvoicesPageQuery>({
        result: query,
        render: (data) => <InvoicesPageView {...data} userMarkets={userMarkets} />,
      });
    },
  });
}

function InvoicesPageView(props: InvoicesPageQuery & { userMarkets: string[] }) {
  const { bills, userMarkets } = props;
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const { queryStorage: filter, setQueryStorage: setFilter } = useQueryStorage<InvoicesFilter>({
    storageKey: "invoicesPageFilter",
    initialQueryStorage: { ...invoiceFilterDefault, marketId: userMarkets },
  });
  const history = useHistory();
  const tid = useTestIds({});

  const filterOptions = useMemo(
    () => ({
      address: bills
        .map((b) => b.project.buildAddress)
        .map((addrs) => ({ id: addrs.street1, name: addrs.street1 }))
        .sortByKey("name")
        .uniqueByKey("name"),

      status: bills
        .map((b) => billStatusChipTypeMapper(b.status.code)[1])
        .map((status) => ({ id: status, name: status }))
        .uniqueByKey("name"),
      markets: bills
        .map((b) => b.project.market)
        .uniqueByKey("id")
        .sortByKey("name"),
    }),
    [bills],
  );

  const showInvoiceFilterModal = useCallback(
    (close: VoidFunction) => {
      return <InvoiceFilterModal filterOptions={filterOptions} filter={filter} setFilter={setFilter} close={close} />;
    },
    [filterOptions, filter, setFilter],
  );
  const filterButtonTrigger = useMemo(() => ({ label: "Filter" }), []);

  const createRowStyles = useCallback(() => {
    function openRowPage(row: GridDataRow<Row>) {
      if (row.kind === "bill") {
        history.push(getInvoiceUrl(row.data.project.id, row.id));
      }
    }
    return {
      bill: { onClick: openRowPage },
    };
  }, [history]);

  return (
    <ScrollableContent virtualized>
      <div css={Css.bgGray100.h100.w100.fg1.py4.px2.bgGray100.ifSm.pt3.$}>
        <div css={Css.mxa.maxwPx(1280).$}>
          <InvoicesSummary bills={bills} />
          <div css={Css.bgWhite.px4.pt1.pb4.$}>
            <div css={Css.df.fdr.jcsb.my4.mb4.ifSm.fdc.mt2.$}>
              <div css={Css.xl2Sb.ifSm.mb2.lgSb.$} {...tid.tableTitle}>
                Invoices
              </div>
              <TableActions>
                <SearchBox onSearch={setSearchFilter} />
                <ButtonModal content={showInvoiceFilterModal} trigger={filterButtonTrigger} />
                {Object.keys(filter).length > 0 && (
                  <Button label="Clear" variant="tertiary" onClick={() => setFilter({} as InvoicesFilter)} />
                )}
              </TableActions>
            </div>
            <div css={Css.oxa.$}>
              <ScrollableContent>
                <GridTable
                  as="table"
                  columns={createColumns()}
                  rows={createRows(bills, {
                    address: filter.address,
                    status: filter.status,
                    dueDateRange: filter.dueDateRange?.value,
                    paidDateRange: filter.paidDateRange?.value,
                    markets: filter.marketId,
                  })}
                  fallbackMessage={searchFilter ? "There are no Invoices matching your search" : "No rows found"}
                  style={{ bordered: false, allWhite: true }}
                  filter={searchFilter}
                  sorting={{ on: "client", initial: ["dueDate", "DESC"] }}
                  rowStyles={createRowStyles()}
                />
              </ScrollableContent>
            </div>
          </div>
        </div>
      </div>
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type BillRow = { kind: "bill"; data: InvoicesPage_BillFragment };
type Row = HeaderRow | BillRow;
type InvoiceTableFilter = {
  address: InputMaybe<string[]>;
  status: InputMaybe<string[]>;
  dueDateRange: DateRange | undefined;
  paidDateRange: DateRange | undefined;
  markets: InputMaybe<string[]>;
};

function createRows(bills: InvoicesPage_BillFragment[], filter: InvoiceTableFilter): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...bills
      .filter((b) => handleFilter(b, filter))
      .map((b) => ({
        kind: "bill" as const,
        id: b.id,
        data: b,
      })),
  ];
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      id: "invoice#",
      header: "Invoice #",
      bill: (b) => ({
        content: () => `${getInvoicePrefix(b, true)} #${b.tradePartnerNumber}`,
        sortValue: b.tradePartnerNumber,
      }),
      w: "70px",
    }),
    column<Row>({
      header: "Reference Number",
      bill: ({ referenceNumber }) =>
        referenceNumber
          ? {
              content: referenceNumber && <Chip text={referenceNumber} title={referenceNumber} type="dark" />,
              value: referenceNumber,
            }
          : emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: emptyCell,
      bill: (b) => {
        const [type, name] = billStatusChipTypeMapper(b.status.code);
        return { content: () => <Chip type={type} text={name || ""} />, value: b.status.code };
      },
      w: "140px",
    }),
    column<Row>({
      header: "Address",
      bill: ({ project }) => project.buildAddress.street1,
      w: "160px",
    }),
    column<Row>({
      header: "PO/CO #",
      bill: ({ parents }) =>
        chipCell(parents.map((p) => `${p.__typename === "Commitment" ? "PO" : "CO"}  #${p.accountingNumber}`)),
      w: "120px",
    }),
    column<Row>({
      id: "dueDate",
      header: {
        content: "Due Date",
        tooltip: "Payment will be initiated by this date. Disbursement make take a few days.",
      },
      bill: ({ dueDate }) => ({
        content: dateCell(dueDate).content(),
        sortValue: dueDate?.getTime() ?? Number.MAX_SAFE_INTEGER,
      }),
      w: "120px",
    }),
    column<Row>({
      header: "Paid On",
      bill: ({ paidDate }) => dateCell(paidDate),
      w: "100px",
    }),
    column<Row>({
      header: "Total",
      bill: ({ billedInCents, isTradePartnerCredit }) => {
        return priceCell({ valueInCents: isTradePartnerCredit ? -billedInCents : billedInCents });
      },
      w: "130px",
    }),
    column<Row>({
      header: emptyCell,
      bill: ({ id, project }) => (
        <Link to={getInvoiceUrl(project.id, id)} css={Css.df.fdr.gap1.$}>
          View Invoice <Icon icon="arrowRight" inc={2} />
        </Link>
      ),
      clientSideSort: false,
      w: "100px",
    }),
  ];
}

function handleFilter(b: InvoicesPage_BillFragment, filter: InvoiceTableFilter) {
  // return early if nothing is filtered
  if (
    Object.values(filter)
      .compact()
      .every((v) => Array.isArray(v) && v.isEmpty)
  )
    return b;
  return Object.entries(filter).every(([k, v]) => {
    if ((Array.isArray(v) && v.nonEmpty) || (!Array.isArray(v) && isDefined(v))) {
      return foldEnum(k, {
        address: isDefined(filter["address"]) && filter["address"].includes(b.project.buildAddress.street1),
        status: isDefined(filter["status"]) && filter["status"].includes(billStatusChipTypeMapper(b.status.code)[1]),
        dueDateRange: dateFilter(b.dueDate, v),
        paidDateRange: dateFilter(b.paidDate, v),
        markets: isDefined(filter.markets) && filter.markets.includes(b.project.market.id),
      });
    }
    return b;
  });
}

export const invoiceFilterDefault: InvoicesFilter = {
  address: [],
  status: [],
  dueDateRange: undefined,
  paidDateRange: undefined,
  marketId: [],
};
