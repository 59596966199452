import {
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  SelectField,
  collapseColumn,
  column,
  emptyCell,
  simpleHeader,
  useSessionStorage,
} from "@homebound/beam";

import { Price, emptyCellDash, priceCell } from "src/components";
import {
  PurchaseOrderPage_CommitmentFragment,
  PurchaseOrderPage_LineItemsFragment,
  PurchaseOrderPage_TradeLineItemFragment,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { foldEnum, formatList } from "src/utils";
import { CommitmentLikeType } from "../detailPane/utils";

enum ItemsGroupBy {
  Templates = "template",
  ItemCode = "itemCode",
}

export function PurchaseOrderTemplatesTable(props: { commitmentlike: CommitmentLikeType }) {
  const { commitmentlike } = props;
  const { lineItems } = commitmentlike;
  const enableProductConfigPlan =
    "commitment" in commitmentlike
      ? commitmentlike.commitment.project.enableProductConfigPlan
      : commitmentlike.project.enableProductConfigPlan;
  const hasProratedLines =
    enableProductConfigPlan && lineItems.some((cli) => cli.primaryBidContractLineItem?.prorations.nonEmpty);
  const itemTemplateItems = lineItems.map((li) => li.projectItem.itemTemplateItem).compact();
  const [templatesGroupBy, setTemplatesGroupBy] = useSessionStorage("templatesTable", ItemsGroupBy.ItemCode);

  return (
    <div css={Css.mt6.$}>
      <div css={Css.df.fdr.jcsb.mb4.$}>
        <div css={Css.lgSb.$}>Item Codes</div>
        <TableActions>
          <div>
            {!hasProratedLines && (
              <SelectField
                compact
                sizeToContent
                label="Group by"
                labelStyle={"inline"}
                options={[
                  ...(itemTemplateItems.nonEmpty ? [{ id: ItemsGroupBy.Templates, name: "Plan and Option" }] : []),
                  { id: ItemsGroupBy.ItemCode, name: "Item Code" },
                ]}
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                value={templatesGroupBy}
                onSelect={(gb) => gb && setTemplatesGroupBy(gb)}
              />
            )}
          </div>
        </TableActions>
      </div>

      <GridTable
        as="table"
        rows={rows(lineItems, templatesGroupBy, hasProratedLines)}
        columns={columns()}
        style={{ bordered: false, allWhite: true }}
        rowStyles={{
          header: { cellCss: Css.gray600.$ },
          total: { cellCss: Css.smSb.mt2.$ },
          group: { cellCss: Css.xsSb.$ },
        }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: string | PurchaseOrderPage_TradeLineItemFragment[] };
type ItemRow = { kind: "item"; data: PurchaseOrderPage_LineItemsFragment };
type TotalRow = { kind: "total"; data: { value: number; title: string } };

export type Row = HeaderRow | GroupRow | ItemRow | TotalRow;

function rows(
  lineItems: PurchaseOrderPage_CommitmentFragment["lineItems"],
  groupedBy: ItemsGroupBy,
  hasProratedLines: boolean,
): GridDataRow<Row>[] {
  const groupedItis = hasProratedLines
    ? groupByProratedLines(lineItems)
    : foldEnum(groupedBy, {
        template: groupByTemplate(lineItems),
        itemCode: groupByItemCode(lineItems),
      });
  const subtotalInCents = lineItems.sum((li) => li.taxableCostInCents);
  const salesTaxInCents = lineItems.sum((li) => li.taxInCents);
  return [
    simpleHeader,
    ...groupedItis,
    ...(salesTaxInCents !== 0
      ? [
          { kind: "total" as const, id: "subtotal", data: { value: subtotalInCents, title: "Subtotal" } },
          { kind: "total" as const, id: "salesTax", data: { value: salesTaxInCents, title: "Sales Tax" } },
        ]
      : []),
    {
      kind: "total" as const,
      id: "total",
      data: { value: lineItems.sum((li) => li.costChangeInCents || 0), title: "Total Amount Due" },
    },
  ];
}

function groupByTemplate(lis: PurchaseOrderPage_LineItemsFragment[]): GridDataRow<Row>[] {
  const groupedItems = lis.groupBy((li) => li.projectItem.baseOrOptionName);
  return Object.entries(groupedItems).map(([templateName, clis]) => {
    return {
      kind: "group" as const,
      id: templateName,
      data: templateName,
      pin: templateName === "Base" ? { at: "first" as const, filter: true } : undefined,
      children: clis.map((cli) => ({
        kind: "item" as const,
        id: cli.id,
        data: cli,
      })),
    };
  });
}

function groupByItemCode(lis: PurchaseOrderPage_LineItemsFragment[]): GridDataRow<Row>[] {
  const groupedItems = lis.groupBy((li) => li.projectItem.item.costCode.displayName);
  return Object.entries(groupedItems).map(([itemCode, clis]) => {
    return {
      kind: "group" as const,
      id: itemCode,
      data: clis.first?.projectItem.displayName ?? "-",
      children: clis.map((li) => ({
        kind: "item" as const,
        id: li.id,
        data: li,
      })),
    };
  });
}

function groupByProratedLines(lis: PurchaseOrderPage_LineItemsFragment[]): GridDataRow<Row>[] {
  // If the CLI has modifies, return the core + modifies as separate line items
  const lineItems = lis
    .flatMap((cli) =>
      cli.modifyingBidContractLineItems.isEmpty
        ? cli
        : [cli.primaryBidContractLineItem, ...cli.modifyingBidContractLineItems].compact().map((bcli) => ({
            ...cli,
            id: bcli.id,
            primaryBidContractLineItem: bcli,
            taxableCostInCents: bcli?.totalCostInCents,
            taxInCents: 0,
            costChangeInCents: bcli?.totalCostInCents,
          })),
    )
    .sortBy((cli) => `${cli.projectItem.item.fullCode} ${cli.projectItem.name}`);
  // Group by trade line item
  const groupedItems = lineItems.groupBy(
    (cli) => cli.primaryBidContractLineItem?.prorations.map((li) => li.tradeLineItem.id).join("") ?? "",
  );
  return Object.entries(groupedItems).map(([tradeId, clis]) => {
    return {
      kind: "group" as const,
      id: tradeId,
      data: lis.first?.primaryBidContractLineItem?.prorations.map((proration) => proration.tradeLineItem) ?? [],
      children: clis.map((li) => ({ kind: "item" as const, id: li.id, data: li })),
    };
  });
}

function columns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>(),
    column<Row>({
      id: "qty",
      header: "QTY",
      group: (data) => ({
        content: Array.isArray(data) ? formatList(data.map((data) => data.productOffering.displayName)) : data,
        colspan: 3,
      }),
      item: ({
        quantity,
        projectItem: {
          unitOfMeasure: { useQuantity },
        },
      }) => (useQuantity ? quantity : emptyCellDash),
      total: emptyCell,
      w: "200px",
    }),
    column<Row>({
      id: "uom",
      header: "UoM",
      group: emptyCell,
      item: ({ projectItem: { unitOfMeasure } }) => unitOfMeasure.abbreviation.toLowerCase(),
      total: emptyCell,
      w: "100px",
    }),
    column<Row>({
      id: "itemCode",
      header: "Item Code",
      group: emptyCell,
      item: ({ projectItem: { item } }) => item.fullCode,
      total: emptyCell,
      w: "150px",
    }),
    column<Row>({
      id: "description",
      header: "Description",
      group: emptyCell,
      item: ({ projectItem: { name } }) => name,
      total: ({ title }) => title,
      w: "200px",
    }),
    column<Row>({
      id: "unitCost",
      header: "Unit Cost",
      group: emptyCell,
      item: ({ projectItem, primaryBidContractLineItem, quantity, taxableCostInCents }) =>
        projectItem.unitOfMeasure.useQuantity && !primaryBidContractLineItem?.prorations.nonEmpty
          ? priceCell({ valueInCents: (taxableCostInCents || 0) / (quantity || 1) })
          : emptyCellDash,
      total: emptyCell,
      w: "140px",
    }),
    column<Row>({
      id: "totalCost",
      header: "Total Cost",
      // Show cost for each trade line item
      // Labor tasks associated with multiple trade line items will have their costs accounted for in each individual trade line
      group: (data) =>
        Array.isArray(data) && data.length === 1 ? priceCell({ valueInCents: data[0].totalCostInCents }) : emptyCell,
      item: ({ primaryBidContractLineItem, taxableCostInCents }) =>
        primaryBidContractLineItem?.prorations.nonEmpty ? emptyCell : priceCell({ valueInCents: taxableCostInCents }),
      total: ({ value }) => <Price valueInCents={value} />,
      w: "160px",
    }),
  ];
}
